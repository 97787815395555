body {
  top:0;
  height: 100%;
  margin: 0;
}

#root{
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
}

